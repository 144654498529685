<template>
  <div>
    <v-container v-if="qr">
      <v-row class="my-5">
        <v-col>
          <h1>חיוב לקוח</h1>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title class="d-flex align-center">
          <h3>שם ההורה: {{ user.firstName }} {{ user.lastName }}</h3>
        </v-card-title>
        <v-card-subtitle class="d-flex align-start">
          <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
        </v-card-subtitle>
        <v-card-title class="d-flex align-center">
          <h4>שם הילד: {{ kid.name }}</h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>יכול לקנות עד {{ limit }} ₪</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                ref="amount-input"
                v-model="form.amount"
                @input="checkLimit"
                @blur="checkLimit"
                @focus="$event.target.select()"
                hide-details
                :max="limit"
                min="0"
                append-icon="mdi-currency-ils"
                label="סכום לתשלום"
                outlined
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                v-model="form.title"
                label="פירוט"
                outlined
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn :disabled="!form.amount" color="success" @click="submit">
                תשלום
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-else class="text-center">
      <h1>הקוד פג תוקף</h1>
      <h3>עוברים חזרה לעמוד הראשי</h3>
    </v-container>
  </div>
</template>

<script>
import { successSwal, errorSwal } from "@/actions.js";
export default {
  name: "PaymentPage",
  data() {
    return {
      form: { amount: 0, title: "" },
    };
  },
  computed: {
    qr() {
      return this.$store.getters.qr;
    },
    limit() {
      if (!this.kid) return 0;
      return this.kid?.credits?.find(
        (c) => c.credit == (this.qr.credit?._id || this.qr.credit),
      )?.amount;
    },
    user() {
      return this.$store.getters.qr?.user || {};
    },
    kid() {
      const kidId = this.$store.getters.qr?.kid;
      if (!kidId) return {};
      return (
        this.$store.getters.qr.user.kids.find((k) => k._id === kidId) || {}
      );
    },
  },
  methods: {
    checkLimit(amount) {
      const isLimited = Number(amount) > Number(this.limit);
      if (isLimited) {
        this.amountApproved = Number(this.limit);
        this.$refs["amount-input"].internalValue = this.limit;
        this.$refs["amount-input"].lazyValue = this.limit;
        this.$refs["amount-input"].$data.lazyValue = this.limit;
      }
      if (amount < 0) {
        this.amountApproved = 0;
        this.$refs["amount-input"].internalValue = 0;
        this.$refs["amount-input"].lazyValue = 0;
        this.$refs["amount-input"].$data.lazyValue = 0;
      }
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("qr/company/show", this.$route.params.code);
      this.loading = false;
    },
    async submit() {
      if (!this.form.amount) {
        return;
      }
      try {
        const data = {
          kidId: this.kid._id,
          parentId: this.user._id,
          credit: this.qr.credit?._id || this.qr.credit,
          code: this.$route.params.code,
          ...this.form,
        };
        await this.$store.dispatch("company/pay", data);
        successSwal({ title: "תשלום בוצע בהצלחה" });
        this.$router.push("/company/dashboard");
      } catch (error) {
        errorSwal({ title: "אירעה שגיאה בתשלום" });
        // TODO: handle error
        console.log(error);
      }
    },
  },
  async mounted() {
    await this.loadData();
    // location.reload();
    if (!this.qr) {
      setTimeout(() => this.$router.go(-1), 3000);
    }
  },
};
</script>
<style scoped lang="scss">
a:link {
  text-decoration: none;
}
</style>
